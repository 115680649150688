@import '~reset-css/sass/_reset.scss';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
@font-face {
    font-family: 'HKNova';
    font-style: normal;
    font-weight: normal;
    src: url('./assets/fonts/HKNova/HKNova-Medium.otf') format('opentype');
}

@font-face {
    font-family: 'HKNova';
    font-style: normal;
    font-weight: 600;
    src: url('./assets/fonts/HKNova/HKNova-Medium.otf') format('opentype');
}

@font-face {
    font-family: 'HKNova';
    font-style: normal;
    font-weight: 700;
    src: url('./assets/fonts/HKNova/HKNova-SemiBold.otf') format('opentype');
}

@font-face {
    font-family: 'HKNova';
    font-style: normal;
    font-weight: 800;
    src: url('./assets/fonts/HKNova/HKNova-Bold.otf') format('opentype');
}

@font-face {
    font-family: 'HKNova';
    font-style: normal;
    font-weight: bold;
    src: url('./assets/fonts/HKNova/HKNova-Bold.otf') format('opentype');
}

@font-face {
    font-family: 'HKNova';
    font-style: normal;
    font-weight: 900;
    src: url('./assets/fonts/HKNova/HKNova-ExtraBold.otf') format('opentype');
}

$font-stack: 'HKNova';
html,
body {
    font-family: $font-stack;
    scrollbar-width: none;
    background: #eee;
}

html::-webkit-scrollbar {
    display: none;
}

.custom-slick-dots {
    bottom: 5px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}